import { constants } from "@/core/vTourConstants";
export default {
  methods: {
    isStartVueTour(value) {
      if (value) {
        this.navigateTo("/dashboard");
        this.$store.commit("SET_VUE_TOUR_STATUS", true);
        this.$tours["myTour"].start();
      }
    },
    startTour() {
      this.responsiveSidenavOpen = false;
      this.isStartVueTour(true);
    },
    onStart() {
      document.getElementById("userFluidLayoutMenuToggle").style.zIndex =
        constants.zIndex99;
      let MainViewWrapper = document.getElementById(
        "userFluidLayoutMainViewWrapper"
      );
      MainViewWrapper.classList.remove("is__highlighted");
      MainViewWrapper.classList.remove("highlight_discover");
      MainViewWrapper.classList.remove("highlight_recommendations");
    },
    onSkip() {
      let MainViewWrapper = document.getElementById(
        "userFluidLayoutMainViewWrapper"
      );
      MainViewWrapper.classList.remove("is__highlighted");
      MainViewWrapper.classList.remove("highlight_discover");
      MainViewWrapper.classList.remove("highlight_recommendations");
      this.$store.commit("SET_VUE_TOUR_STATUS", false);
    },
    onStop() {
      this.onSkip();
    },
    myCustomNextStepCallback(currentStep) {
      // Wait for the next DOM update cycle
      setTimeout(() => {
        let MainViewWrapper = document.getElementById(
          "userFluidLayoutMainViewWrapper"
        );
        if (currentStep === -1) {
          document.getElementById("userFluidLayoutMenuToggle").style.zIndex =
            constants.zIndex99;
        } else {
          document.getElementById("userFluidLayoutMenuToggle").style.zIndex =
            constants.zIndex9;
        }
        if (currentStep === 0) {
          MainViewWrapper.classList.remove("highlight_discover");
          MainViewWrapper.className +=
            " is__highlighted highlight_recommendations";
        } else if (currentStep === 1 && window.innerWidth > 480) {
          MainViewWrapper.classList.remove("highlight_recommendations");
          MainViewWrapper.className += " is__highlighted highlight_discover";
        } else {
          MainViewWrapper.classList.remove("is__highlighted");
          MainViewWrapper.classList.remove("highlight_discover");
          MainViewWrapper.classList.remove("highlight_recommendations");
        }
      }, 100);
    }
  }
};
